@import "~/src/scss/breakpoints";

.content__header{  
  width:100%;
  background:var(--color-background);  
  box-sizing: border-box;
  border-bottom:2px solid var(--color-card-background);
  padding-top:var(--spacing-l);
  padding-bottom:var(--spacing-l);
  margin-bottom:var(--spacing-s);

  h1{
    margin:0;
    margin-left:var(--spacing-m);
    font-size:20px;
  }

  @media(max-width: $breakpoint-small){
    height:unset;
    padding-top:var(--spacing-m);
    padding-bottom:var(--spacing-m);
  }
}

.content__posts{
  margin-bottom:var(--spacing-xxl);
}