.placeholder{
  position:relative;
  display:flex;
  aspect-ratio: 16/9;
  margin:var(--spacing-m);
  box-sizing: border-box;
  background:var(--color-text);
  padding-bottom:var(--spacing-m);

  color:var(--color-background);
  font-size:22px;
  justify-content: center;
  align-items: center;

  .swipe-left{
    display:none;
    position:absolute;
    left:var(--spacing-m);
    width:50px;
    height:50px;
    border-radius:50%;
    background:red;
  }

  .swipe-right{
    display:none;
    position:absolute;
    right:var(--spacing-m);
    width:50px;
    height:50px;
    border-radius:50%;
    background:green;
  }

  &:hover{
    .swipe-left, .swipe-right{
      display:inline-block;
      &:hover{
        cursor:pointer;
      }
    }
  }
}

.swipe__wrapper{
  width:100%;
  overflow:hidden;
}