@import "~/src/scss/breakpoints";

.player__wrapper{
  display:none;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  z-index:999;
  .player-play{
    display:flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    width:50px;
    height:50px;
    background:var(--color-primary);
    border-radius:50%;
    z-index:999;
    border:5px solid black;
    transform: scale(1);
    transition: transform .2s;
    opacity:.4;

    .icon{
      width:40px;
      height:40px;
      stroke:black;
      stroke-width:3px;
      fill:white;
      margin-left:4px;
    }

    &:hover{
      cursor:pointer;
      transform: scale(1.2);
    }
  }

  .player__container{
    max-height:100%;
    position: relative;
    width: 100%;
    height: 100%;
    .player{
      max-height:100%;
      border-radius: var(--border-radius);
    }
    &:hover{
      cursor:pointer;
    }
  }

  &.player__wrapper--inline{
    position:absolute;
    display:flex;
    border-radius:var(--border-radius);

    .player__container{
      .player__aligner{
        position: relative;
        height: inherit;
      }
      .player__spacer{
        display:none;
      }
      .player{
        height:100%;
        background: black;
      }
    }

    &:hover{
      cursor:pointer;
    }
  }

  &.player__wrapper--small{
    position:fixed;
    display:flex;
    left:0 !important;
    top:unset !important;
    bottom:-130px;
    width:100% !important;
    height:116px;
    z-index:1;
    box-sizing: border-box;
    .player__container{
      position:relative;
      display:flex;
      width:100%;
      max-width:1400px;
      height:100%;
      margin-left:auto;
      margin-right:auto;
      box-sizing: border-box;
      .player__spacer{
        flex:29;
      }
      .player__aligner{
        position: relative;
        height: 100%;
        flex:5;
        .player{
          position: absolute;
          object-fit: contain;
          background: black;
          display:flex;
          background:black;
          z-index:999;
          margin-top:-188px;
          width:100%;
          height:100%;
          box-sizing: border-box;
          border-radius: 0;
          aspect-ratio: 16/9;
        }
      }
    }

    @media(max-width: $breakpoint-small){
      position:fixed;
      right:133px;
      bottom:11px;
      top:unset !important;
      left:unset !important;
      width:49px !important;
      height:31px !important;
      z-index:9999;      

      .player__container{
        .player__aligner{
          flex:unset;
          .player{
            position:relative;
            margin-top:unset;
            border-radius:2px;
          }
        }
      }      
    }
  }
  &.player__wrapper--fullscreen{

    position:fixed;
    display:flex !important;
    top:0 !important;
    left:0 !important;
    right:0 !important;
    bottom:0 !important;
    width:unset !important;

    .player{
      position:fixed;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index:9999;
      margin-top:unset !important;
      background:black;
    }
  }

  &:hover{
    div{
      opacity:1;
    }
  }
  .player{
    width:100%;
    height:100%;
  }
}
