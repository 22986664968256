@import "~/src/scss/breakpoints";

.post{
  display:flex;
  max-width:100%;
  border-bottom:2px solid var(--color-card-background);
  margin-bottom:var(--spacing-s);
  padding:var(--spacing-m);
  box-sizing: border-box;

  .post__avatar{
    width:52px;
    height:52px;
    border-radius:50%;
    background:black;
    margin-right:var(--spacing-m);
    flex-shrink: 0;
    img{
      width:100%;
      height:100%;
      border-radius:50%;
    }
  }

  .post__info{
    display:flex;
    flex-direction: column;
    width:100%;
    min-width:0;
    .post__author{
      display:flex;      
      // width:calc(100% - 100px);
      .name{
        flex-shrink: 1;
        margin-right:var(--spacing-xs);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a{
          color:var(--color-text);
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      .handle{
        flex-shrink: 1;
        opacity:.6;
        margin-right:var(--spacing-xxs);
        font-family: Arial, sans-serif;
        font-weight:500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .timestamp{
        display:flex;
        flex-shrink: 0;
        flex-grow: 1;
        opacity:.6;
        font-family: Arial, sans-serif;
        font-weight:500;
        margin-right:var(--spacing-s);
        span{
          opacity:.6;
          margin-left:var(--spacing-xxs);
          margin-right:var(--spacing-xs);
        }

        &:hover{
          text-decoration: underline;
        }
      }
      .menu{
        margin-left:auto;
        margin-top:4px;
      }

    }
  }

  .post__content{
    font-family: Arial, sans-serif;
    padding-bottom:var(--spacing-m);    
    p{
      font-weight:100;      
    }    
    iframe{
      aspect-ratio: 16/9;
      border:0;
      border-radius:var(--border-radius);
    }
  }

  .post__actions{
    display: flex;
    .post__action__wrapper{
      display: flex;
      flex:1.5;
      justify-content: center;
      &:first-of-type{
        flex:1;
        justify-content: flex-start;
      }
      &:last-of-type{
        flex:1;        
        justify-content: flex-end;
        .icon{
          margin-right:0;
        }
      }
    }
    .post__action{   
      display: flex;
      align-items: center;      
      transition: color .2s;

      .icon{
        width:22px;
        margin-right:var(--spacing-s);
        fill:var(--color-text);
        stroke:none;           
        border-radius:50%;
        padding:6px;
        transition: fill .2s, background .2s;
      }

      &:hover{
        cursor:pointer;
      }

      &.post__action--comments{
        &:hover{
          color:var(--color-primary);          
          .icon{
            fill:var(--color-primary);
            background-color:rgba(var(--color-primary-base), .1);
          }
        }        
      }
      &.post__action--reposts{
        &:hover{
          color:#1cac00;
          .icon{
            fill:#1cac00;
            background-color:rgba(28, 172, 0, .1);
          }
        }        
      }
      &.post__action--likes{
        &:hover{
          color:red;
          .icon{
            fill:red;
            background-color:rgba(255, 0, 0, .1);
          }
        }        
      }
      &.post__action--bookmark{
        .icon{
          fill:transparent;
          stroke:var(--color-text);
        }
        &:hover{
          color:var(--color-primary);
          .icon{
            stroke:var(--color-primary);
            background-color:rgba(var(--color-primary-base), .1);
          }
        }        
      }
    }
  }

  @media(max-width: $breakpoint-small){
    padding:var(--spacing-xs) var(--spacing-s);
    .post__actions{
      .post__action{
        .icon{
          margin-right:var(--spacing-xxs);
        }
      }
    }    
  }
  
}


.video-placeholder{
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:var(--spacing-s);
  border-radius:var(--border-radius);
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  aspect-ratio: 16/9;

  .video-placeholder__play{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100px;
    height:100px;
    border-radius:50%;
    background-color:white;
    border:8px solid black;
    background-color:var(--color-primary);
    transform: scale(1);
    transition: transform .2s;

    .icon{
      width:100%;
      height:100%;
      fill:white;
      margin-left:10px;
    }
    &:hover{
      cursor:pointer;
      transform: scale(1.2);
    }
  }
}