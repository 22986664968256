@import "~/src/scss/breakpoints";

html{
  height:100%;
}
body {
  font-size: 11pt;
  font-family: 'Roboto', Arial, sans-serif;
  color:var(--color-text);
  height:100%;  
  box-sizing: border-box;
  background-color:var(--color-background);
  margin:0;
  overflow-y:scroll;
  overflow-x:hidden;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/Roboto-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

a{
  color:var(--color-text);
  text-decoration: none;
}

#root, .app{
  height:100%;
}
.app-wrapper{
  width:100%;
  height:100%;
  margin-bottom:60px;

  .app-container{
    display:flex;    
    max-width:1400px;
    min-height:100%;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
    z-index:1;

    @media(max-width: $breakpoint-small){
      flex-direction: column;
    }
  }

  .app-content{
    flex:21;
    border-left:2px solid var(--color-card-background);
    border-right:2px solid var(--color-card-background);
    box-sizing: border-box;
    
    @media(max-width: $breakpoint-small){
      flex:1;
      width:100%;
      margin-top:46px;
      border:unset;
    }
  }
}

:root {
  --spacing-xxl: 6rem;
  --spacing-xl: 4rem;
  --spacing-l: 2rem;
  --spacing-m: 1rem;
  --spacing-s: 0.6rem;
  --spacing-xs: 0.4rem;
  --spacing-xxs: 0.2rem;

  // --color-primary-base: 112, 91, 255;
  --color-primary-base: 79, 105, 250;
  --color-primary: rgba(var(--color-primary-base), 1);
  --border-radius: 12px;
}

[theme="dark"] {
  --color-background-base: 17, 17, 17;
  --color-background: rgba(var(--color-background-base),1);
  --color-background-card-base: 34, 34, 34;
  --color-card-background:rgba(var(--color-background-card-base),1);
  --color-text:#ffffff;
}

[theme="bright"] {
  --color-background-base: 255, 255, 255;
  --color-background: rgba(var(--color-background-base),1);
  --color-background-card-base: 240, 241, 244;
  --color-card-background:rgba(var(--color-background-card-base),1);
  --color-text:#000000;
}