@import "~/src/scss/breakpoints";

.explorer__wrapper{
  position:relative;
  flex:8;
  @media(max-width: $breakpoint-small){
    display:none;
    flex:0;
  }
}

.explorer{
  position:sticky;
  display:flex;
  flex-direction: column;
  top:0;
  width:100%;
  padding-top:var(--spacing-l); 
  padding-left:var(--spacing-m);
  box-sizing: border-box;
}

.explorer__search{
  display:flex;
  align-items: center;
  position:relative;
  width:100%;
  height:40px;
  background:var(--color-card-background);
  margin-bottom:var(--spacing-m);
  border-radius:20px;
  padding:0 var(--spacing-m) 0 var(--spacing-m);
  font-size:large;
  box-sizing: border-box;

  input{
    background:transparent;
    border:0;
    outline:0;
    color:var(--color-text);
    font-size:16px;
  }

  div{
    margin-left:auto;
    &:hover{
      cursor:pointer;
      color:var(--color-primary);
    }
  }
}

.explorer__card{
  position:relative;
  width:100%;
  background:var(--color-card-background);
  border-radius:var(--border-radius);
  margin-bottom:var(--spacing-m);    
}

.explorer__card-title{
  display:flex;
  font-weight:700;
  font-size:20px;
  padding:var(--spacing-m);

  .icon{
    stroke:var(--color-text);
    margin-right:var(--spacing-s);
  }
}

.explorer__card-row{
  display:flex;
  padding:var(--spacing-s) var(--spacing-m);  

  &.explorer__card-row--more{
    padding:var(--spacing-m);
    color:var(--color-primary);
  }

  a{
    width:100%;
  }

  &:hover{
    cursor:pointer;
    background-color:rgba(var(--color-background-base),.4);
  }
}

.explorer__card-tabs{
  display:flex;
  padding-bottom:0;
  .explorer__card-tab{
    display:flex;
    align-items: center;
    flex:1;
    padding:var(--spacing-s);
    font-weight: 700;
    font-size: 20px;

    .icon{
      stroke:var(--color-text);
      margin-right:var(--spacing-s);
    }
    .bubble{
      display:flex;
      justify-content: center;
      align-items: center;
      width:16px;
      height:16px;      
      margin-left:auto;
      border-radius:50%;
      border:2px solid white;
      font-size:10px;
      background:red;
      color:white;
    }

    &:hover{
      cursor:pointer;
      background-color:rgba(var(--color-background-base),.4);
    }
  }
}

.explorer__card-tab-content{
  margin-top:0;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  height:0;
  overflow:hidden;
  transition: height .2s;
}

.channel-preview{
  display:flex;  
  width:100%;
  .channel-preview__thumbnail{
    width:48px;
    height:48px;
    border-radius:50%;
    margin-right:var(--spacing-s);
    flex-shrink: 0;
    background-color:var(--color-background);
  }

  .channel-preview__infos{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .channel-preview__title{
      color:var(--color-text);
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    .channel-preview__handle{      
      opacity:.6;
      color:var(--color-text);
      text-decoration: none;
      font-family: Arial, sans-serif;
      font-weight:500;
    }
  }    
}


.message-preview{
  display:flex;  
  width:100%;  
  .message-preview__thumbnail{
    width:48px;
    height:48px;
    border-radius:50%;
    margin-right:var(--spacing-s);
    flex-shrink: 0;
    background-color:var(--color-text);
  }

  .message-preview__infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;

    .message-preview__title{
      display:flex;
      color:var(--color-text);
      text-decoration: none;
      align-items: center;

      div{
        margin-left:auto;
        width:6px;
        height:6px;
        background:red;
        border-radius:50%;
      }
      &:hover{
        text-decoration: underline;
      }
    }
    .message-preview__text{      
      opacity:.6;
      color:var(--color-text);
      text-decoration: none;
      font-family: Arial, sans-serif;
      font-weight:500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }    
}
