.post-header{  
  
  .post-header__navigation{
    position:sticky;
    top:0;
    padding-top:var(--spacing-m);
    display:flex;
    font-size:24px;
    padding-bottom:var(--spacing-m);
    z-index:99;
    backdrop-filter: blur(4px);
    background-color:rgba(var(--color-background-base),.9);
    
    .post-header__navigation-back{
      display:flex;
      justify-content: center;
      align-items: center;

      width:50px;
      height:50px;
      line-height:50px;
      font-size:40px;
      margin-left:var(--spacing-m);
      margin-right:var(--spacing-m);      
      border-radius:50%;
      transition:background .2s;

      &:hover{
        cursor:pointer;
        background-color:var(--color-card-background);        
      }
    }    
    .post-header__title{
      display:flex;
      flex-direction: column;
      justify-content: center;

      label{
        font-size:12px;
        opacity:.6;
      }
    }
  }

  .post-header__wallpaper{
    position:relative;
    width:100%;
    height:200px;
    background-position: 50% 50%;
    background-size: cover;    

    img{
      padding:4px;
      position:absolute;
      border-radius:50%;
      bottom:-50px;
      left:var(--spacing-m);
      width:130px;
      height:130px;
      background-color:var(--color-background);
    }    
  }

  .post-header__meta{    
    border-bottom:2px solid var(--color-card-background);
    padding-left:var(--spacing-m);
    padding-bottom:var(--spacing-m);

    .user-header__follow{
      display:flex;
      width:100%;
      div{
        background:var(--color-text);
        color:var(--color-background);
        margin-left:auto;
        border-radius:22px;
        padding:10px 18px;
        margin-top:var(--spacing-s);
        margin-right:var(--spacing-m);
        margin-bottom:var(--spacing-m);
        font-size:18px;

        &:hover{
          cursor:pointer;
          background-color:var(--color-primary);
          color:white;
        }
      }
    }

    h1{
      font-size:24px;
    }
    p{
      font-family: Arial, sans-serif;
    }
  }
}