@import "~/src/scss/breakpoints";

.controls__wrapper{
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  height:60px;
  background-color:#010101;
  z-index:999;
  transition:bottom .4s;

  .controls{
    display:flex;    
    max-width:1400px;
    height:100%;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
    align-items: center;
    color:#ffffff;
    transition:max-width .2s;

    .controls__main{
      display:flex;
      align-items: center;
      flex:5;
      width:100%;
    }

    .controls__sub{
      display:flex;
      align-items: center;
      flex:29;
      height:100%;
    }

    .button{
      display: flex;
      align-items: center;
      color:black;
      flex-shrink: 0;

      &.button--player-mode{
        position:relative;
        width:50px;
        height:30px;
        background:var(--color-background);
        border-radius:4px;
        border: 2px solid var(--color-text);
        margin-left:var(--spacing-xs);

        .mode--small{
          position:absolute;
          left:8px;
          right:31px;
          top:18px;
          bottom:4px;
          background:var(--color-text);
          border-radius:4px;
        }

        .mode--inline{
          position:absolute;
          left:8px;
          right:8px;
          top:3px;
          bottom:10px;
          background:var(--color-text);
          border-radius:4px;
        }

        .mode--fullscreen{
          position:absolute;
          left:3px;
          right:3px;
          top:3px;
          bottom:3px;
          background:var(--color-text);
          border-radius:4px;
        }     

        &.button--player-mode--active{
          border: 2px solid var(--color-primary);
        }
        
        
        &:hover{
          border: 2px solid var(--color-primary);
        }
      }      

      &:hover{
        cursor:pointer;        
      }
    }

    .button--player-mode--disabled{
      opacity:.4;
      pointer-events: none;
      &:not(a){
        border: 2px solid var(--color-text);
      }      
    }

    .status{
      width:56px;
      height:56px;
      border-radius:50%;

      .icon--play{
        width:56px;
        height:56px;        
        stroke:transparent;
        fill:white;
      }
      .icon--pause{
        width:32px;
        height:32px;
        stroke:black;
        stroke-width: 3px;
        margin-left:8px;
        fill:white;
        stroke:white
      }
    }

    .volume{
      margin-left:var(--spacing-xs);
      border-radius:50%;      

      .icon{
        fill:white;
        stroke:white;
        stroke-width:.5px;
        width:26px;
        height:26px;
      }
    }

    .controls__progress{
      width:100%;
      height:100%;
      padding-left:var(--spacing-m);
      padding-right:var(--spacing-m);
      box-sizing: border-box;

      .controls__meta{
        display:flex;
        align-items: flex-end;
        height:60%;
        a{
          display:flex;
          align-items: center;
          color:white;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
        img{
          width:20px;
          height:20px;
          border-radius:50%;
          margin-right:var(--spacing-s);
        }
      }
    }

    .timecodes{
      font-family: Arial, sans-serif;
      font-size:14px;
      margin-left:auto;
      margin-right:var(--spacing-s);
      flex-shrink: 0;
    }

    .progress-bar{
      position:relative;
      width:100%;      
      height:40%;
      border-radius:2px;  
      padding-top:var(--spacing-xs);

      .progress-bar__bar{
        position:absolute;
        width:100%;
        height:3px;
        background:white;
        border-radius:2px;
      }

      .progress-bar__progress{
        position:absolute;
        height:3px;
        background:var(--color-primary);
        border-radius:2px;
        transition: width .1s ease;
      }

      .progress-bar__position{
        position:absolute;
        width:14px;
        height:14px;
        border-radius:50%;
        margin-top:-6px;
        margin-left:-4px;
        background:var(--color-primary);
        transition: left .1s ease;
      }
      .progress-bar__timestamp{
        position:absolute;
        display:flex;
        justify-content: center;
        width:50px;
        height:28px;
        background:black;
        bottom:71px;
        margin-left:-25px;
        border-radius:4px;
        padding:var(--spacing-xs);
        box-sizing: border-box;

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          border: solid;
          border-width: 5px 5px 0;
          border-color: black transparent transparent transparent;
          transform: translateX(-50%);
        }
      }

      &:hover{
        cursor: pointer;
      }
    }

    .player-modes{
      display:flex;
    }
  }

  &.controls__wrapper--fullscreen{
    .controls{
      .timecodes{
        margin-left:var(--spacing-s);
        margin-right:var(--spacing-m);
      }
    }
  }

  &.controls__wrapper--disabled{
    pointer-events: none;
    .controls{
      opacity:.2;
    }    
  }
  
  @media(min-width: $breakpoint-small){
    &.controls__wrapper--fullscreen{
      background-color:rgba(1,1,1,.8);
      backdrop-filter: blur(4px);
      .controls{
        max-width:100%;
        margin-left:var(--spacing-m);
        margin-right:var(--spacing-m);
  
        .controls__main{
          flex:unset;
          width:unset;
        }

        .controls__sub{
          flex:unset;
          width:100%;        
        }
  
        .volume{        
          margin-right:var(--spacing-s);
        }
      }    
    }
  }  

  &.controls__wrapper--hidden{
    bottom:-80px;
  }


  @media(max-width: $breakpoint-small){
    height:80px;
    .controls{
      display:block;
      position:relative;
      max-width:100%;
      margin:unset;

      .controls__main{
        flex:unset;
        position:absolute;
        left:0;
        bottom:0;
      }
      .controls__sub{       
        flex:unset; 
        .controls__progress{          
          position:unset;
          .controls__meta{
            display:none;
          }
          .progress-bar{
            position:absolute;
            top:var(--spacing-s);
            left:var(--spacing-s);
            right:var(--spacing-s);
            width:unset;
          }          
        }
      }

      .status{        
        top:0;
        left:var(--spacing-s);
      }

      .player-modes{        
        position:absolute;
        right:var(--spacing-s);
        bottom:var(--spacing-s);
      }

      .controls__progress{
        position:absolute;
        bottom:0;
      }

      .timecodes{
        margin-left:var(--spacing-m);
      }
    }    
  }
}