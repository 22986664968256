@import "~/src/scss/breakpoints";

.navigation__wrapper{
  position:relative;
  flex:5;
  @media(max-width: $breakpoint-small){
    flex:0;    
    &.navigation__wrapper--blur{
      position:fixed;
      left:0;
      right:0;
      top:0;
      bottom:0;    
      background-color:rgba(var(--color-background-base),.6);
      z-index:999;
      backdrop-filter: blur(4px);
    }

  }
}

.navigation{
  display:flex;
  flex-direction: column;
  position:sticky;
  top:0;  
  font-weight:900; 
  padding-top:var(--spacing-l);   
  box-sizing: border-box;

  .navigation__logo{
    display: flex;
    align-items: flex-end;
    font-size:20px;    
    font-size:20px;

    .logo__wrapper{
      display:flex;
    }
    .logo{
      display:flex;
      width:26px;
      height:26px;
      background-color:var(--color-primary);
      border-radius:4px;
      font-weight:bold;
      justify-content: flex-end;
      align-items: flex-end;
      color:white;
      padding-right:2px;
      margin-right:2px;
      margin-bottom:-2px;
      box-sizing: border-box;
      border:2px solid black;
    }
  }
  .theme-toggle{
    position:relative;
    margin-left: auto;
    margin-right:var(--spacing-s);
    padding:2px;
    width:40px;
    height:20px;
    border-radius:12px;
    background:var(--color-text);

    div{
      background-color:var(--color-background);
      width:20px;
      height:20px;
      border-radius:50%;
      transition:margin-left .4s;
    }

    .icon{
      position:absolute;
      width:14px;
      top:0;
      
      &.icon--bright{
        left:5px;
        fill:black;
      }
      &.icon--dark{
        right:5px;
        fill:white;
        stroke:transparent;
      }
    }

    &.theme-toggle--dark{
      div{
        margin-left:20px;
      }      
    }

    &:hover{
      cursor:pointer;
    }
  }

  .navigation__entries{
    display:flex;
    flex-direction: column;
    margin-top:var(--spacing-xl);    
    font-size:18px;
    .navigation__entry{
      display:flex;
      align-items: center;
      margin-top:var(--spacing-xs);
      margin-bottom:var(--spacing-s);
      padding:var(--spacing-xs);
      margin-left:calc(var(--spacing-xs) * -1);
      border-radius:20px 0 0 20px;
      border-right:unset;

      div{
        display:flex;
        align-items: center;
        width:100%;
      }

      .navigation__entry-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width:28px;
        height:28px;
        border-radius:50%;
        background-color:var(--color-text);
        margin-right:var(--spacing-s);
        padding:2px;

        .icon{          
          stroke:var(--color-background);
          stroke-width: 2px;
          fill:var(--color-text);
        }
      }

      img{
        background-color:var(--color-text);
        width:32px;
        height:32px;
        border-radius:50%;
        margin-right:var(--spacing-s);
      }

      &:hover{
        cursor:pointer;
        background-color:var(--color-card-background);
        .navigation__entry-icon{
          background-color:var(--color-primary);
        }
      }

      &.navigation__entry--active{
        background-color:var(--color-card-background);
        border-right:unset;        
        .navigation__entry-icon{
          background-color:var(--color-primary);
        }
        &:hover{
          cursor:default;
        }
      }
    }
  }

  .quick-navigation__entries{
    display:none;
  }

  @media(max-width: $breakpoint-small){
    position:fixed;
    left:0;
    right:0;
    height:46px;
    padding:var(--spacing-s);
    border-bottom:2px solid var(--color-card-background);
    background-color:var(--color-background);
    z-index:999;
    transition: top .4s;
    .theme-toggle{
      margin-right:0;
    }
    .navigation__entries{
      position:fixed;
      top:46px;
      right:-50%;
      background-color:var(--color-background);
      margin-top:0;
      padding:var(--spacing-m);
      padding-right:0;
      width:50%;
      border-left:2px solid var(--color-card-background);
      height:100%;
      box-sizing: border-box;
      z-index: 999;
      transition:right .2s;
      &.navigation__entries--visible{
        right:0;
      }
    }    
    .quick-navigation__entries{
      display:flex;      
      .quick-navigation__entry{
        display:flex;
        justify-content: center;
        align-items: center;
        width:24px;
        height:24px;
        border-radius:50%;
        background:var(--color-text);
        margin-left:var(--spacing-xs);
        transform: rotate(0deg);
        transition: transform .2s;

        .icon{
          font-size:16px;
          line-height:16px;          
          stroke:var(--color-text);
          fill:var(--color-text);
          filter: invert(100%);

          &.icon--notifications{
            margin-top:3px;
          }
          &.icon--menu{
            padding:3px;
            stroke-width: 4px;;
          }
        }

        &.quick-navigation__entry--active{
          transform: rotate(90deg);
          background-color:var(--color-primary);

          .icon{
            filter:unset;
          }
        }
      }
    }

    &.navigation--hidden{
      top:-46px;
    }
  }
}